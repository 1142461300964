<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.room_id" name="room_id" id="room_id" class="form-control form-control-sm" >
                <option :value="''">Select a room</option>
                <option v-for="(room, index) in rooms" :key="index"  :value="room.id">{{ room.name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <input type="text" v-model="search_data.room_booking_number"  id="room_booking_number" name="room_booking_number" class="form-control form-control-sm" placeholder="Enter room booking number"/>
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-sm text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Room</th>
              <th>Requested Date</th>
              <th>Time Slot</th>
              <th>Room Booking No.</th>
              <th>Department</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(room_booking, i) in room_bookings.data" :key="i">
              <td>{{ room_bookings.from + i}}</td>
              <td>{{ room_booking.room.name }}</td>
              <td>{{ customBookingDate(room_booking.booking_date) }}</td>
              <td>
                  <span v-for="(room_booking_time_slot, index) in room_booking.sorted_room_booking_time_slots" :key="index">
                    {{ room_booking_time_slot.time_slot }} <br/>
                  </span>
              </td>
              <td>{{ room_booking.room_booking_no }}</td>
              <td>{{ room_booking.department.department_name }}</td>
              <td>
                <a href="javascript: void(0);" v-b-modal.room-booking-status-modal @click="$bvModal.show('room-booking-status-modal'), status(room_booking.max_room_booking_status, room_booking)" class="btn btn-sm btn-primary">
                  <i class="fe fe-eye"/>
                </a>
              </td>
              <td>{{ customDate(room_booking.created_at) }}</td>
              <td>
                <div class="d-flex justify-content-center">
                  <router-link :to="{ name: 'adminRoomBookingView', params: { room_booking_id: room_booking.id } }" class="btn btn-sm btn-info mr-2 ant-btn" tag="a">
                    <i class="fe fe-eye"/> View
                  </router-link>
                  <a-button :loading="btnAdminApproved && i == index" v-if="room_booking.room_booking_status.includes('ADMIN_PENDING') && !room_booking.room_booking_status.includes('ADMIN_APPROVED') && !room_booking.room_booking_status.includes('ADMIN_REJECTED')" class="btn btn-success btn-sm mr-2" @click.prevent="adminApprovedStatus(room_booking.id, index = i)"><i class="fa fa-check"></i> Approve</a-button>
                  <a-button :loading="btnAdminReject && i == index" v-if="room_booking.room_booking_status.includes('ADMIN_PENDING') && !room_booking.room_booking_status.includes('ADMIN_APPROVED') && !room_booking.room_booking_status.includes('ADMIN_REJECTED')" class="btn btn-danger btn-sm mr-2" @click.prevent="adminRejectStatus(room_booking.id, index = i)"><i class="fa fa-times"></i> Reject</a-button>
                  <a-dropdown placement="bottomRight" :trigger="['click']">
                    <button type="button" class="btn btn-outline-success" style="padding: 0 .75rem !important;">
                      <i class="fe fe-more-vertical" /> Download
                    </button>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a-button class="btn btn-outline-success btn-block mr-2" :loading="pdfDownloadLoader && i == index" @click.prevent="pdfDownload(room_booking.id, index = i)"><i class="fa fa-file-pdf-o mr-1"></i> PDF</a-button>
                      </a-menu-item>
                      <a-menu-item>
                        <a-button class="btn btn-outline-success btn-block mr-2" :loading="fullPdfDownloadLoader && i == index" @click.prevent="fullPdfDownload(room_booking.id, index = i)"><i class="fa fa-file-pdf-o mr-1"></i> Room Booking PDF</a-button>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="room_bookings.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="room_bookings" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <status :current="current" :room_booking="room_booking"></status>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import Status from '@/views/roomBooking/admin/partial/status'

export default {
  name: 'List',
  components: { Empty, Status },
  data() {
    return {
      rooms: [],
      room_bookings: {},
      room_booking: {},
      loading: false,
      loader: false,
      flag: false,
      index: -1,
      btnLoading: false,
      btnAdminReject: false,
      btnAdminApproved: false,
      pdfDownloadLoader: false,
      excelDownloadLoader: false,
      approveBtnLoading: false,
      fullPdfDownloadLoader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      current: 0,
      search_data: {
        room_id: '',
        room_booking_number: '',
        date_range: '',
      },
    }
  },
  mounted() {
    this.getCodes()
  },
  methods: {
    getCodes() {
      apiClient.get('api/admin/rooms')
        .then(response => {
          this.rooms = response.data.rooms
        })
        .catch(error => {
          console.log(error)
        })
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customBookingDate(date) {
      return moment(date).format('MMMM Do YYYY')
    },
    customDate(date) {
      return moment(date).format('LLL')
    },
    search() {
      this.loading = true
      this.btnLoading = true
      this.flag = true
      apiClient.post('api/admin/pending/room-booking/search', this.search_data)
        .then(response => {
          this.loading = false
          this.btnLoading = false
          this.flag = true
          this.room_bookings = response.data.room_bookings
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/admin/pending/room-booking/search?page=' + page, this.search_data)
        .then(response => {
          this.room_bookings = response.data.room_bookings
        })
    },
    adminRejectStatus(roomBookingId, index) {
      if (confirm('Do you really want to reject?')) {
        this.btnAdminReject = true
        apiClient.post('api/admin/room-booking/reject', { roomBookingId: roomBookingId }).then(response => {
          this.btnAdminReject = false
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.search()
            }
          } else {
            this.$notification.error({
              message: 'Room Booking Rejected',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    adminApprovedStatus(roomBookingId, index) {
      if (confirm('Do you really want to approved?')) {
        this.btnAdminApproved = true
        apiClient.post('api/admin/room-booking/approved', { roomBookingId: roomBookingId }).then(response => {
          this.btnAdminApproved = false
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.search()
            }
          } else {
            this.$notification.error({
              message: 'Room Booking Approved',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    status(roomBookingStatus, roomBooking) {
      this.current = 0
      this.room_booking = ''
      this.current = roomBookingStatus
      this.room_booking = roomBooking
    },
    pdfDownload(roomBookingId, index) {
      this.pdfDownloadLoader = true
      apiClient.get('api/room-booking/pdf-download/' + roomBookingId, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'room_booking.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    fullPdfDownload(roomBookingId, index) {
      this.fullPdfDownloadLoader = true
      apiClient.get('api/room-booking/full-pdf-download/' + roomBookingId, { responseType: 'blob' }).then(response => {
        this.fullPdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'room_booking.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
